.App {
  font-family: Helvetica Neue, Arial,
    sans-serif;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: .12em;
  word-spacing: .16em;
  text-align: center;
}

.footer {
  transform: translateX(38%);
  display: flex;
  gap: 1rem;
}

.footer-logo {
  height: 2rem;
  width: auto;
}

.license {
  transform: translateY(-.6rem);
}

.nav {
  position: sticky;
  z-index: 1;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.nav-bar {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}

.nav-logo {
  height: 4rem;
  width: auto;
  position: absolute;
  left: 1rem;
}

.hover {
  margin: 0 -.5rem;
}

.dropdown .dropbtn {
  border: none;
  background-color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  color: black;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding-bottom: 1rem;
}

.dropdown-content a {
  color: black;
  padding: 0 0 3rem 4rem;
  display: block;
  text-align: left;
}

.hover:hover .dropdown-content {
  display: block;
}

.projects-hr {
  transform: translateY(2.2rem);
  color: #8A898C;
}

.nav-bar a {
  transform: translate(-2rem, 2.3rem);
}

.nav-bar a {
  color: black;
  text-decoration: none;
}

.nav-bar a:hover {
  text-decoration: underline;
}

.remodel-out {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8A898C;
  font-size: 4rem;
  letter-spacing: -0.1rem;
  animation-delay: 1s;
}

.repair-in {
  animation-delay: 1s;
}

.repair-out {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8A898C;
  font-size: 4rem;
  letter-spacing: -0.1rem;
  animation-delay: 2s;
}

.reimagine-in {
  animation-delay: 2s;
}

.reimagine-out {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #8A898C;
  font-size: 4rem;
  letter-spacing: -0.1rem;
  animation-delay: 3s;
}

.home-logo {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -35%);
  width: 30rem;
  height: auto;
  animation-duration: 6s;
  animation-delay: 3.5s;
}

.about {
  overflow: hidden;
}

.about-red-block {
  background: #E12726;
  color: white;
  text-align: left;
  padding: 5rem;
}

.bathroom-renovation-pic-container {
    width: 41rem;
    height: 27rem;
    float: right;
    margin: 1rem;
    overflow: hidden;
    transform: translateY(-.8rem);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.bathroom-renovation-pic {
    width: 45rem;
    height: auto;
    transition: 2s ease-in-out;
}

.bathroom-renovation-pic:hover {
  filter: saturate(1.5);
  transform: scale(1.2);
}

.about-white-block {
  padding: 5rem;
}

.owners-pic-container {
  width: 30rem;
  height: 40rem;
  float: left;
  margin: 1rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.owners-pic {
  width: 30rem;
  height: auto;
  transition: 2s ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transform: scale(1.4);
}

.owners-pic:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transform: scale(1.5);
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-evenly;
  padding: 5rem 0;
}

.gallery img {
  width: 45rem;
  height: auto;
}

.before-after {
  padding-top: 5rem;
}

.connect {
  background: #009344;
  padding: 5rem;
}

.connect-pic-container {
  width: 38rem;
  height: 41rem;
  float: right;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.connect-pic {
  width: 38rem;
  height: auto;
  transition: 2s ease-in-out;
}

.connect-pic:hover {
  filter: saturate(1.5);
  transform: scale(1.2);
}

.connect-divs {
  display: flex;
  flex-direction: column;
}

.connect a {
  color: white;
}

.connect a:hover {
  color: black;
}

.connect-icons {
  width: 6rem;
  height: auto;
}

.connect hr {
  width: 15rem;
  border: 1px solid #8A898C;
}

@media only screen and (max-width: 450px) {

  .nav-bar {
    gap: 1rem;
    }

}

@media only screen and (max-width: 700px) {
    p,
      ul,
      a,
      h2 {
        font-size: 3vw;
      }

}

@media only screen and (max-width: 1060px) {

  .nav-logo {
    height: 2rem;
    width: auto;
  }

  .home-logo {
    width: 90%;
    height: auto;
    max-width: 30rem;
  }

  .bathroom-renovation-pic-container {
    float: none;
    margin: auto;
    width: 100%;
    max-width: 41rem;
    height: auto;
  }

  .bathroom-renovation-pic {
    width: 110%;
    height: auto;
    transform: scale(1.1);
  }

  .owners-pic-container {
    float: none;
    margin: auto;
    width: 90%;
    max-width: 25rem;
    height: auto;
  }

  .owners-pic {
    width: 100%;
    height: auto;
  }

  .gallery img {
    width: 95%;
    height: auto;
    max-width: 45rem;
  }

  .connect-pic-container {
    float: none;
    margin: auto;
    margin-bottom: 3rem;
    width: 90%;
    max-width: 25rem;
    height: auto;
  }

  .connect-pic {
  width: 100%;
  height: auto;
  transform: scale(1.1);
  }

}


@media only screen and (max-height: 400px) {

  p,
    ul,
    a,
    h2 {
      font-size: 1.5vw;
  }

  .nav {
    position: fixed;
    z-index: 1;
    left: 0;
    width: 25%;
    height: 100vh;
  }

  .nav-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .nav-logo {
    height: 2rem;
    width: auto;
    position: absolute;
    left: 1rem;
  }

  .nav-bar a {
    transform: translateY(2.5rem);
  }
    
  .hover:hover,
  .nav:hover .dropdown-content {
    display: block;
    top: -10rem;
    left: 7rem;
  }

  .dropdown-content {
    padding: 0 1rem 4rem 1rem;
    box-shadow: none;
  }
  
  .dropdown-content a {
    padding: .5rem 0;
  }

  .projects-hr {
    display: none;
  }

  .about, .connect, .home, .bathrooms, .decks, .green-street, .kitchens, .rhode-island, .states-street {
    position: absolute;
    top: 0;
    left: 25%;
    padding-top: 2rem;
  }

  .about {
    padding-top: 0;
    margin-top: -3rem;
  }

  .remodel-out, .repair-out, .reimagine-out {
    font-size: 2rem;
  }

  .home-logo {
    width: 10rem;
    transform: translate(-5rem, .5rem);
  }

  .bathroom-renovation-pic-container {
    float: none;
  }

  .owners-pic-container {
    width: 13rem;
  }

  .connect hr {
    width: 8rem;
    border-color: black;
  }

  .connect-pic-container {
    float: right;
    width: 15rem;
  }

  .connect-pic {
    width: 15rem;
  }

  .connect-icons {
    width: 3rem;
    height: auto;
  }

}